* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #eee;
}

.preview-list {
  margin: auto;
  width: max-content;
}

.preview {
  color: black;
  width: 60ch;
  max-width: 100vw;
  background-color: white;
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
}
